/* terms starts here */
.terms {
  padding: 200px 0px 10px 0px;
  background: var(--accent-color-1);
}
.terms .terms-heading-wrapper {
  text-align: center;
}
.terms .terms-content-wrapper .terms-heading-wrapper h2 {
  font-size: 62px !important;
}
.terms .terms-content-wrapper .terms-text-wrapper p {
  margin-bottom: 50px;
  font-size: 14px !important;
  line-height: 1.8 !important;
  font-weight: 500 !important;
}
/* terms ends here */
@media (max-width: 800px) {
  .terms .terms-content-wrapper .terms-heading-wrapper h2 {
    font-size: 54px !important;
  }
}
