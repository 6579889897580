/* responsiive on ipad starts here */
@media (max-width: 800px) {
	.banner {
		height: auto;
	}
	.banner-imgs-wrapper .row .row .col-lg-3 {
		flex: 0 0 50%;
	}
	.banner .banner-imgs-wrapper .col-lg-3:nth-child(2) {
		margin: 0px;
	}
	.banner .banner-imgs-wrapper .col-lg-3:nth-child(3) {
		margin: 0px;
	}
	.banner .banner-imgs-wrapper .banner-img-wrapper img {
		height: 304px;
	}
	.banner .banner-imgs-wrapper {
		margin-top: 50px;
	}
	.koze-app {
		height: auto;
	}
	.print-sec {
		height: auto;
		background-image: none;
	}
	.emotions-sec {
		padding-top: 0px;
		height: auto;
		background-image: none;
	}
	.print-sec .print-content-wrapper {
		padding-top: 35px;
	}
	.emotions-sec .emotions-content-wrapper {
		padding-top: 30px;
	}
	.shake-sec .button-wrapper {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.shake-sec .shake-biscuits-wrapper {
		text-align: center;
	}
	.koze-app .koze-app-2-wrapper {
		height: 327px;
		margin-top: 141px;
		width: 100%;
	}
	.play-game {
		background: var(--accent-color-2);
		height: auto;
		background-image: none;
	}
	.shake-sec {
		height: auto;
		background-image: none;
	}
	.koze-cookies {
		background: #f9ec6c;
		height: auto;
		background-image: none;
	}
}
/* responsiive on ipad ends here */
@media (max-width: 480px) {
	.banner-1-sec .banner-1-text-wrapper {
		margin-left: 0px !important;
		margin-top: 40px;
	}
	.loading-wrapper img {
		width: 50%;
		margin: 0px auto;
	}
	.banner-1-sec .banner-1-text-wrapper h2 {
		font-size: 40px !important;
	}
	.banner-side-img-wrapper ul {
		flex-direction: revert;
	}
	.banner-1-sec .banner-1-img-wrapper img {
		height: auto;
		width: 100%;
	}
	.banner-1-sec .banner-1-text-wrapper {
		margin-bottom: 44px;
	}
	.about-sec .locations-wrapper .location-btn:last-child {
		margin-right: 0px;
	}
	.about-sec .locations-wrapper .location-btn:first-child {
		margin-right: 0px;
	}
	.about-sec .locations-wrapper .location-btn {
		flex: 0 0 48% !important;
	}
	.footer-wrapper {
		overflow-x: hidden;
	}
	.about-sec .locations-wrapper {
		display: flex;
		justify-content: space-between;
	}
	.banner {
		height: auto;
	}
	.banner-social-links-wrapper {
		display: none;
	}
	.banner .banner-imgs-wrapper .col-lg-3:nth-child(2) {
		margin: 0px;
	}
	.banner .banner-imgs-wrapper .col-lg-3:nth-child(3) {
		margin: 0px;
	}
	.banner .banner-imgs-wrapper {
		margin-top: 50px;
	}
	.banner-imgs-wrapper .row .row .col-lg-3 {
		flex: 0 0 50%;
	}
	.banner .banner-imgs-wrapper .banner-img-wrapper img {
		height: auto;
		width: 100%;
	}
	.banner .banner-content-wrapper h1:first-child {
		font-size: 32px !important;
		line-height: 1.5 !important;
	}
	.banner .banner-content-wrapper span.pink {
		color: var(--accent-color-2);
		font-size: 37px !important;
		margin: 0px;
		line-height: 1 !important;
		font-weight: 700;
		margin-top: 19px;
	}
}
@media (max-width: 425px) {
	.about-koze-cookies .about-koze-cookies-wrapper {
		padding-top: 30px;
	}
	.aboutsec .about-page-content-wrapper h2 {
		font-size: 41px !important;
		line-height: 1.2 !important;
	}
	.about-koze-shake .about-koze-head-wrapper h2 {
		font-size: 40px !important;
	}
	.about-koze-shake .about-koze-shake-wrapper h2 {
		font-size: 40px !important;
	}
	.about-koze-cookies .about-koze-cookies-wrapper h2 {
		font-size: 40px !important;
	}
}
@media (max-width: 414px) {
	.about-koze-shake .about-location-card-wrapper {
		padding: 13px 18px 16px 18px;
	}
	footer .footer-logo-wrapper {
		left: -106px;
	}
}
/* about responsive starts here */
@media (max-width: 480px) {
	.koze-app {
		background: var(--accent-color-1);
		background-image: none;
	}

	.shake-sec {
		overflow-x: hidden;
	}
	.play-game {
		background: var(--accent-color-2);
		background-image: none;
	}
	.play-game .play-game-content-wrapper .buttons-wrapper a.apple-btn {
		background: #000;
		margin-bottom: 18px;
		display: block;
		text-align: center;
	}
	.about-sec {
		overflow-x: hidden;
	}
	.after-banner .marquee img {
		height: 60px;
		object-fit: cover;
	}
	section#about-sec .about-content-wrapper {
		margin-top: 58px;
	}
}

/* koze app responsive starts here */
@media (max-width: 480px) {
	.overlay ul li .menu-item-wrapper a {
		font-size: 25px;
	}
	#overlay .off-canvas-menu-wrapper {
		margin-bottom: 32px;
	}
	.koze-app .koze-app-1-wrapper .buttons-wrapper {
		display: flex;
		flex-direction: column;
	}
	.koze-app .koze-app-1-wrapper .koze-app-content-wrapper {
		flex: 0 0 100%;
		width: 100%;
	}
	.koze-app .koze-app-1-wrapper .buttons-wrapper a.apple-btn {
		background: #000;
		padding: 9px 22px;
		margin: 0px 0px 0px 0px;
		border-radius: 35px;
		width: 100%;
		text-align: center;
		margin-bottom: 12px;
	}
	.koze-app {
		height: auto;
	}
	.koze-cookies {
		height: auto;
	}
	.play-game {
		height: auto;
	}
	.print-sec {
		height: auto;
		background-image: none;
	}
	.print-sec .print-content-wrapper {
		margin-top: 30px;
	}
	.emotions-sec {
		height: auto;
		background-image: none;
		padding-top: 0px;
	}
	.emotions-sec .emotions-content-wrapper {
		padding-top: 36px;
	}
	.koze-app .koze-app-2-wrapper {
		height: auto;
		margin-top: 40px;
	}
	.koze-app .koze-app-2-wrapper {
		flex-direction: column-reverse;
	}
	.koze-app .koze-app-1-wrapper {
		height: auto;
		flex-direction: column-reverse;
	}
	.koze-app .koze-app-2-wrapper .koze-app-content-wrapper {
		flex: 0 0 100%;
		width: 100%;
	}
	.koze-app .koze-app-2-wrapper .koze-app-img-wrapper img.app-scanner-img {
		left: 18%;
		width: 100px;
		object-fit: cover;
		top: 42%;
	}
	.shake-sec {
		background-image: none;
	}
	.about-sec .locations-wrapper .location-btn h6 {
		font-size: 16px !important;
	}
	.about-sec .locations-wrapper .location-btn img {
		height: 44px;
	}
}
@media (max-width: 375px) {
	.aboutsec {
		padding-top: 171px;
		padding-bottom: 80px;
	}
	.about-Mobile-content-wrapper .buttons-wrapper {
		flex-direction: column-reverse;
	}
	.about-Mobile-content-wrapper a.apple-btn {
		padding: 12px 16px;
		margin-bottom: 13px;
	}

	.about-sec .about-testimonials-wrapper {
		flex-direction: column-reverse;
	}
	.about-sec .about-testimonials-wrapper .about-testimonails-heading-wrapper {
		flex: 0 0 100%;
		width: 100%;
	}
	.about-sec .about-testimonials-wrapper .about-testimonails-img-wrapper {
		flex: 0 0 100%;
		width: 100%;
	}
	.about-sec .about-testimonials-wrapper .about-testimonails-img-wrapper ul {
		justify-content: flex-start;
		margin: 0px;
		margin-left: 15px;
		margin-bottom: 10px;
	}
	.koze-app .row:first-child {
		margin-bottom: 0px !important;
	}
	.shake-sec .shake-biscuits-wrapper ul img {
		height: 98px;
		width: 98px;
	}
	.shake-sec .shake-biscuits-wrapper img {
		height: 221px;
		object-fit: contain;
	}
	.about-sec .about-content-wrapper h2 {
		font-size: 36px !important;
	}
	.emotions-sec .emotions-content-wrapper h2 {
		font-size: 39px !important;
		line-height: 1.3 !important;
		margin-top: 20px;
	}
	.banner .banner-content-wrapper span.pink {
		font-size: 27px !important;
	}
	.play-game .play-game-content-wrapper h2 {
		font-size: 40px !important;
	}
	#about-sec .about-content-wrapper {
		margin-top: 22px !important;
	}
	#about-sec .about-content-wrapper {
		margin-top: 27px;
	}
	.about-sec .locations-wrapper .location-btn h6 {
		font-size: 12px !important;
	}
	.koze-app .shake-heading-wrapper h2 {
		font-size: 35px !important;
	}
	.print-sec .print-content-wrapper h2 {
		font-size: 45px !important;
	}
	.koze-cookies .koze-cookies-content-wrapper h2 {
		font-size: 46px !important;
	}
	.about-location-detail .about-loc-detail-img-wrapper .scanner-main-wrapper {
		background: #fff;
		padding: 11px 6px 0px 6px;
		border-radius: 14px;
		width: 198px;
		border: 2px solid var(--accent-color-2);
		outline: 3px solid #fff;
		bottom: -30%;
	}
	.about-koze-shake .about-koze-head-wrapper h2 {
		font-size: 35px !important;
	}
}
@media (max-width: 360px) {
	.overlay ul li p {
		font-weight: 500 !important;
		font-size: 13px !important;
		margin: 0px;
	}
}
@media (max-width: 320px) {
	.print-sec .print-content-wrapper h2 {
		font-size: 33px !important;
	}
	.loading-wrapper img {
		width: 70%;
		margin: 0px auto;
	}
	.koze-cookies .koze-cookies-content-wrapper h2 {
		font-size: 39px !important;
	}
	.koze-app .koze-app-1-wrapper {
		padding: 24px 18px 12px;
	}
	.koze-app .koze-app-2-wrapper {
		padding: 24px 18px 12px;
		width: 100%;
	}
	.shake-sec .shake-biscuits-wrapper img {
		height: 212px;
	}
	.shake-sec .shake-biscuits-wrapper ul img {
		height: 84px;
		width: 84px;
		object-fit: cover;
	}
	.emotions-sec .emotions-content-wrapper h2 {
		font-size: 40px !important;
	}
	.play-game .play-game-content-wrapper h2 {
		font-size: 35px !important;
	}
	.print-sec .print-content-wrapper h4 {
		font-size: 24px !important;
		font-weight: bold !important;
	}
	footer {
		overflow-x: hidden;
	}
	footer .footer-logo-wrapper {
		left: -144px;
	}
	.newsletter
		.newsletter-card-wrapper
		.newsletter-fields-wrapper
		.btn
		span.btn-icon {
		margin-left: 16px;
	}
	.about-location-detail .about-loc-detail-content-wrapper h2 {
		font-size: 33px !important;
	}
	.about-koze-shake .about-Mobile-card-wrapper {
		padding: 13px 18px 16px 18px;
	}
	.about-koze-shake .about-franchise-wrapper {
		padding: 30px 0px 0px 18px;
	}
	.about-koze-shake .about-franchise-wrapper .about-franchise-content-wrapper {
		width: 100% !important;
	}
	.off-canvas-wrapper .close-btn-wrapper {
		margin-right: 44px;
	}
	.overlay.open .off-canvas-menu-wrapper li {
		padding: 25px 16px;
	}
	.off-menu-policy-wrapper ul {
		flex-direction: column;
	}
	.off-menu-policy-wrapper ul li {
		margin-bottom: 10px;
	}
	.terms .terms-content-wrapper .terms-heading-wrapper h2 {
		font-size: 41px !important;
		line-height: 1.3 !important;
	}
	.terms .terms-text-wrapper {
		padding-top: 20px !important;
	}
	.contact-sec .contact-info-wrapper {
		padding: 68px 11px 29px 11px;
	}
	.contact-sec .contact-heading-wrapper h2 {
		font-size: 32px !important;
	}
	.newsletter .newsletter-card-wrapper .newsletter-heading-wrapper h2 {
		font-size: 28px !important;
		line-height: 1.5 !important;
	}
	.franchise-form-wrapper {
		padding: 25px 12px 30px 12px;
		border-radius: 30px;
	}
	header#top .menu-container a.menu {
		height: 53px;
		width: 53px;
	}
	.about-sec .locations-wrapper {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.about-sec .locations-wrapper .location-btn {
		padding: 8px 21px;
	}
	.about-sec .about-content-wrapper h2 {
		font-size: 31px !important;
	}
	.shake-sec .shake-heading-wrapper h2 {
		font-size: 33px !important;
	}
	.shake-sec .shake-heading-wrapper h2 span::after {
		display: none;
	}
	.koze-app .koze-app-2-wrapper .koze-app-img-wrapper img.app-scanner-img {
		left: 13%;
		width: 100px;
		object-fit: cover;
		top: 42%;
	}
	.emotions-sec .emotions-content-wrapper h2 {
		font-size: 34px !important;
	}
}
@media (max-width: 280px) {
	footer .footer-logo-wrapper img {
		transform: scale(0.7);
	}
	footer .footer-logo-wrapper {
		left: -169px;
	}
	.koze-cookies .cookies-shake-wrapper ul li .cookies-card-wrapper {
		width: 123px;
	}
	.koze-cookies .cookies-shake-wrapper ul li {
		flex: 0 0 50%;
	}
	.koze-cookies .koze-cookies-content-wrapper h2 {
		font-size: 33px !important;
	}
	.play-game .play-game-content-wrapper h2 {
		font-size: 30px !important;
	}
	.emotions-sec .emotions-content-wrapper h2 {
		font-size: 30px !important;
	}
	p {
		font-size: 13px !important;
	}
	.print-sec .print-content-wrapper h2 {
		font-size: 31px !important;
	}
	.print-sec .print-content-wrapper h4 {
		font-size: 20px !important;
	}
	.koze-app .koze-app-2-wrapper .koze-app-img-wrapper img.app-scanner-img {
		left: 10%;
	}
}
