:root {
	/* colors starts here */
	--accent-color-1: #62e0e4;
	--accent-color-2: #fa63c2;
	--accent-color-3: #f0c55b;
	--accent-color-4: #000000;
	/* colors ends here */
	/* typography starts here */
	--font-heading: "Poppins";
	--font-text: "Poppins";
	/* typography ends here */
}
