.our-menu {
	background: var(--accent-color-1);
	padding-top: 200px;
	padding-bottom: 100px;
}

.our-menu .menu-heading-wrapper {
	text-align: center;
}
.our-menu .menu-tabs-btn-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
	justify-content: center;
}
.our-menu .menu-tabs-btn-wrapper {
	padding-top: 40px;
	padding-bottom: 40px;
}
.our-menu .menu-tabs-btn-wrapper ul li button.menu-tab-btn {
	border: 0px;
	padding: 15px 32px;
	border-radius: 33px;
	box-shadow: 0px 3px 2px 0px #8585858c;
	font-size: 22px;
}
.our-menu .menu-tabs-btn-wrapper ul li:first-child button.menu-tab-btn {
	margin-right: 24px;
}
.our-menu .menu-tabs-btn-wrapper ul li button.menu-tab-btn.active {
	background: var(--accent-color-2);
}
.our-menu .menu-tabs-btn-wrapper ul li button.menu-tab-btn.active:hover {
	background: var(--accent-color-3);
}
.our-menu .menu-tabs-btn-wrapper ul li button.menu-tab-btn:hover {
	background: var(--accent-color-2);
}
.our-menu .product-card-wrapper {
	background: #fff;
	border-radius: 49px;
	text-align: center;
	padding: 33px 10px 8px 10px;
	margin-bottom: 53px;
	box-shadow: 0px 4px 5px 0px #8585858c;
	overflow: hidden;
}
.our-menu .product-card-wrapper .product-img-wrapper img {
	height: 165px;
	object-fit: cover;
}
.our-menu .product-card-wrapper .pro-content-wrapper p {
	font-size: 15px !important;
}
.our-menu .menu-tabs-btn-wrapper ul li button.menu-tab-btn span {
	font-weight: 700;
}
/* responsive starts here */
@media (max-width: 1440px) {
	.our-menu .product-card-wrapper .pro-content-wrapper h4 {
		font-size: 25px !important;
	}
	.our-menu .product-card-wrapper .pro-content-wrapper p {
		font-size: 13px !important;
	}
}
@media (max-width: 1366px) {
	.our-menu .product-card-wrapper .product-img-wrapper img {
		object-fit: contain;
	}
}
@media (max-width: 425px) {
	.our-menu .menu-tabs-btn-wrapper ul li button.menu-tab-btn {
		padding: 15px 25px;
		font-size: 19px;
	}
}
@media (max-width: 375px) {
	.our-menu .menu-heading-wrapper h2 {
		font-size: 37px !important;
	}
	.our-menu {
		padding-top: 145px;
	}
	.our-menu .menu-tabs-btn-wrapper ul li button.menu-tab-btn {
		padding: 15px 25px;
		font-size: 16px;
	}
}
@media (max-width: 360px) {
	.our-menu .menu-tabs-btn-wrapper {
		padding-top: 22px;
		padding-bottom: 22px;
	}
	.our-menu .menu-tabs-btn-wrapper ul li button.menu-tab-btn {
		padding: 15px 13px;
		font-size: 16px;
	}
	.our-menu .menu-tabs-btn-wrapper ul {
		flex-direction: column;
	}
	.our-menu .menu-tabs-btn-wrapper ul li {
		margin-bottom: 20px;
	}
	.our-menu .menu-tabs-btn-wrapper ul li button.menu-tab-btn {
		width: 100%;
	}
}
/* responsive ends here */
