@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("./variables.css");

/* universal css starts here */
body {
	overflow-x: hidden;
}
.btn {
	background: var(--accent-color-2) !important;
	border-radius: 29px !important;
	padding: 4px 0px 4px 41px !important;
	display: inline-flex !important;
	align-items: center !important;
	color: #fff !important;
	box-shadow: 1px 4px 5px #ffffff9e inset, 0px 3px 3px #87878794 !important;
	border: 0px !important;
}
.btn span:first-child {
	text-shadow: 2px 2px 4px #545454 !important;
	font-weight: 500;
}
.btn span.btn-icon {
	background: var(--accent-color-1);
	width: 42px;
	display: inline-block;
	height: 42px;
	margin-left: 33px;
	margin-right: 6px;
	line-height: 2.2;
	font-size: 19px;
	border-radius: 50%;
	box-shadow: 1px 4px 5px #ffffff9e inset;
}
.btn span.btn-icon i {
	color: #000;
}
a.apple-btn {
	background: #000;
	padding: 16px 30px;
	margin: 0px 10px 0px 0px;
	border-radius: 35px;
}
/* universal css ends here */

/* typography starts here */
* {
	font-family: var(--font-heading);
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
	color: black;
}
h1 {
	font-family: var(--font-heading);
	font-size: 65px !important;
	font-weight: 400 !important;
	line-height: 85px !important;
}

h1 span {
	font-family: var(--font-heading) !important;
	font-weight: 700 !important;
}

h2 {
	font-family: var(--font-heading) !important;
	font-size: 55px !important;
	font-weight: 400 !important;
	line-height: 72px !important;
}

h2 span {
	font-family: var(--font-heading) !important;
	font-weight: 700 !important;
}

h3 {
	font-family: var(--font-heading) !important;
	font-size: 40px !important;
	font-weight: 400 !important;
	line-height: 45px !important;
}

h3 span {
	font-family: var(--font-heading) !important;
	font-weight: 700 !important;
}

h4 {
	font-family: var(--font-heading) !important;
	font-size: 30px !important;
	font-weight: 400 !important;
	line-height: 35px !important;
}

h4 span {
	font-family: var(--font-heading) !important;
	font-weight: 700 !important;
}

h5 {
	font-family: var(--font-heading) !important;
	font-size: 22px !important;
	font-weight: 400 !important;
	line-height: 28px !important;
}

h5 span {
	font-family: var(--font-heading) !important;
	font-weight: 700 !important;
}

h6 {
	font-family: var(--font-heading) !important;
	font-size: 19px !important;
	font-weight: 400 !important;
	line-height: 24px !important;
}

h6 span {
	font-family: var(--font-heading) !important;
	font-weight: 700 !important;
}
p {
	font-family: var(--font-heading) !important;
	font-size: 15px !important;
	font-weight: 400 !important;
	line-height: 21px !important;
}
/* typography ends here */

/* header css starts here */
header#top {
	/* overflow-x: hidden; */
	padding: 30px 0px 30px 0px;
	position: absolute;
	width: 100%;
	z-index: 9;
}
header#top .off-canvas-btn button.header-btn {
	background: #fff;
	border: 0px;
	border-radius: 0px 0px 14px 0px;
	height: 75px;
	width: 75px;
	opacity: 60%;
}
.overlay.open .off-canvas-menu-wrapper li.active a {
	color: var(--accent-color-2);
	transition: all 0.2s ease-in-out;
}
.overlay.open .off-canvas-menu-wrapper li.active p {
	color: var(--accent-color-2);
	transition: all 0.2s ease-in-out;
}
.off-menu-policy-wrapper ul li a.active {
	color: var(--accent-color-2);
}
header#top .header-dropdown-wrapper select.form-control {
	background: #9aebee;
	border: 0px;
	height: 50px;
	border-radius: 36px;
	font-weight: 700;
	width: 200px;
	padding: 10px 23px;
	background-image: url("../img/down-arrow.webp");
	background-position: 93% center;
	background-repeat: no-repeat;
}
header#top .header-dropdown-wrapper button {
	background: #c0f3f4;
	border: 0px;
	height: 50px;
	border-radius: 36px;
	font-weight: 500;
	width: 200px;
	padding: 10px 23px;
	box-shadow: 0px 2px 2px #91919180;
	cursor: inherit !important;
}
header#top .header-dropdown-wrapper button span {
	font-weight: 700;
}
header#top .header-dropdown-wrapper select.form-control::first-word {
	font-size: 10px !important;
}
header#top .header-logo-wrapper img {
	width: 188px;
}
/* responsive starts here */
@media (max-width: 480px) {
	header#top {
		overflow-x: hidden;
	}
}
/* responsive ends here */
/* off canvas css starts here */

.menu-container.full-menu {
	border-radius: 0;
	padding: 0 !important;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	transition: all 0.3s;
	height: 100vh;
}

.full-menu .menu {
	top: 40px;
	left: 40px;
	opacity: 0;
}

.menu {
	color: white;
	font-size: 3.5em;
	position: absolute;
	top: 160px;
	left: 160px;
	z-index: 100;
	transition: all 0.3s;
}
/* .menu {
	color: white;
	font-size: 3.5em;
	position: absolute;
	bottom: 160px;
	left: 160px;
	z-index: 100;
	transition: all 0.3s;
} */
.menu-down .menu {
	color: white;
	font-size: 3.5em;
	position: absolute;
	bottom: -66px;
	right: -66px;
	top: unset;
	left: unset;
	z-index: 100;
	transition: all 0.3s;
}
.menu i {
	opacity: 0.7;
	transform: scale(1);
	transition: all 0.3s;
}
.menu i:hover {
	opacity: 1;
	transform: scale(1.2);
	transition: all 0.3s;
}
.blob {
	animation: blobby 4s infinite;
}

.blob2 {
	animation: blobby2 6s infinite;
}

@keyframes blobby {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.08);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes blobby2 {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.18);
	}
	100% {
		transform: scale(1);
	}
}
/* .panel {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
} */
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 0%;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	z-index: 109;
	transform: translate(-100%, -84%);
	/* transition: all 1s ease-in-out; */
	border-radius: 100%;
}
.overlay.open {
	opacity: 1;
	visibility: visible;
	height: 100%;
	background: #f9ec6c;
	transform: translate(0%, 0%);
	/* transition: all 1s cubic-bezier(0.11, 0, 0.5, 0) 0s; */
	border-radius: 0%;
}
.off-canvas-wrapper .close-btn-wrapper {
	text-align: end;
	margin-right: 70px;
	margin-bottom: 24px;
	margin-top: 24px;
}
.off-canvas-wrapper .close-btn-wrapper button.btn-closes {
	background: transparent;
	border: 0px;
	font-size: 32px;
}
.off-canvas-wrapper {
	overflow-y: scroll;
	height: 100vh;
	overflow-x: hidden;
	padding-bottom: 36px;
}
.off-canvas-wrapper .off-last-content-wrapper {
	padding-top: 40px;
}
.overlay.open .off-canvas-menu-wrapper li {
	animation-delay: 0.35s;
	border-bottom: 1px dashed #00000087;
	padding: 25px 36px;
	transition: all 0.4s ease-in-out;
}
/* .overlay.open .off-canvas-menu-wrapper li:hover {
	box-shadow: 390px 0px 0px var(--accent-color-2) inset;
} */
.overlay.open .off-canvas-menu-wrapper li:hover a {
	color: var(--accent-color-2);
	margin-left: 10px; /* font-size: 34px; */
	transition: all 0.2s ease-in-out;
}
.overlay.open .off-canvas-menu-wrapper li:hover p {
	color: var(--accent-color-2);
	margin-left: 10px; /* font-size: 34px; */
	transition: all 0.2s ease-in-out;
}

/* div#overlay .off-canvas-menu-wrapper {
	padding-top: 120px;
} */
.overlay.open li:last-child {
	border-bottom: 0px;
}
/* .overlay.open li:nth-of-type(2) {
	animation-delay: 0.4s;
}
.overlay.open li:nth-of-type(3) {
	animation-delay: 0.45s;
}
.overlay.open li:nth-of-type(4) {
	animation-delay: 0.5s;
} */
.overlay nav {
	font-size: 3.2em;
	font-family: "Titan One", san-serif;
	position: relative;
	height: 70%;
	top: 50%;
	transform: translateY(-50%);
	font-weight: 400;
	text-align: center;
}
.overlay ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	position: relative;
	height: 100%;
}
.overlay ul li a {
	display: block;
	position: relative;
	color: #000;
	text-decoration: none;
	overflow: hidden;
	opacity: 1;
	font-size: 32px;
	transition: all 0.3s;
	line-height: 1.3;
}
.overlay ul li p {
	font-weight: 500 !important;
	font-size: 15px !important;
	margin: 0px;
}
.overlay .off-canvas-menu-wrapper ul li a span {
	font-weight: 700 !important;
	font-family: var(--fontheading) !important;
}

header#top .menu-container a.menu {
	background: #c0f3f4;
	border: 0px;
	border-radius: 0px 0px 14px 0px;
	height: 65px;
	width: 65px;
	text-align: center;
	line-height: 1.2;
	display: flex;
	justify-content: center;
	align-items: center;
}
/* header#top .menu-container a.menu {
	background: #c0f3f4;
	border: 0px;
	border-radius: 50%;
	height: 70px;
	width: 70px;
	text-align: center;
	line-height: 1.2;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 7px 2px #878787b3;
} */
/* .menu-container {
	cursor: pointer;
	position: fixed;
	transition: all 0.3s;
	bottom: -119px;
	left: -127px;
} */
.menu-container {
	cursor: pointer;
	position: fixed;
	left: -161px;
	top: -161px;
	transition: all 0.3s;
}
/* header#top .menu-down a.menu {
	border-radius: 14px 0px 0px 0px;
} */
header#top .menu-down a.menu {
	border-radius: 35px;
	margin-right: 27px;
	margin-bottom: 30px;
	box-shadow: 2px 1px 9px 0px rgb(109 109 109 / 37%);
}
.menu-container.menu-down {
	cursor: pointer;
	position: fixed;
	left: unset;
	top: unset;
	transition: all 0.3s;
	right: 65px;
	bottom: 65px;
	z-index: 109;
}
.menu-container.full-menu {
	border-radius: 0;
	padding: 0 !important;
	position: aboslute;
	width: 100%;
	height: 100vh;
	left: 0;
	top: 0;
	transition: all 0.3s;
	background-color: #f9ec6c;
}
.off-canvas-wrapper .off-social-icons-wrapper ul {
	display: flex;
	align-items: center;
	justify-content: end;
	margin: 0px;
}
.off-canvas-wrapper .off-social-icons-wrapper ul li p {
	margin: 0px;
}
.off-canvas-wrapper .off-social-icons-wrapper ul li {
	border: 0px;
}
.off-canvas-wrapper .off-social-icons-wrapper ul li {
	margin-left: 18px;
}
.off-canvas-wrapper .off-social-icons-wrapper ul li p span {
	font-weight: 700;
}
.off-menu-policy-wrapper ul li a {
	font-size: 16px;
}
.off-menu-policy-wrapper ul {
	display: flex;
	margin: 0px;
}
.off-menu-policy-wrapper ul li {
	border: 0px;
}
.off-menu-policy-wrapper ul li {
	border: 0px !important;
	margin-right: 21px;
}
.off-menu-policy-wrapper ul li a span {
	font-weight: 700;
}
.off-canvas-wrapper::-webkit-scrollbar {
	width: 0px !important;
}
.off-canvas-wrapper::-webkit-scrollbar-track {
	box-shadow: none !important;
}
.off-canvas-wrapper::-webkit-scrollbar-thumb {
	background-color: none !important;
	outline: none !important;
}
.off-canvas-wrapper {
	/* width: 0px !important; */
	scrollbar-width: none;
}
.off-canvas-wrapper::-moz-scrollbar-track {
	box-shadow: none !important;
}
.off-canvas-wrapper::-moz-scrollbar-thumb {
	background-color: none !important;
	outline: none !important;
}
/* responsive starts here */
@media (max-width: 1440px) {
	.menu-container.menu-down {
		right: 79px;
	}
}
@media (max-width: 800px) {
	.off-canvas-wrapper .off-social-icons-wrapper ul {
		justify-content: flex-start;
		margin-top: 21px;
	}
	.off-canvas-wrapper .off-social-icons-wrapper ul li:first-child {
		margin-left: 0px;
	}
}
/* responsive ends here */
/* off canvas css ends here */
/* header css ends here */
/* loading starts here */
.loading-wrapper {
	background: var(--accent-color-1);
	height: 100vh;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.loading-wrapper img {
	width: 25%;
	margin: 0px auto;
}
/* loading ends here */
/* banner after slide starts here */
/* banner starts here */
.banner {
	/* background: var(--accent-color-1); */
	background-image: url("../img/ban-bg.webp");
	/* background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/56901/bg-clouds2-tinypng.png"); */
	background-repeat: no-repeat;
	background-size: cover;
	padding: 140px 0px 50px 0px;
	/* position: relative; */
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.banner .banner-content-wrapper {
	text-align: center;
}
.banner .banner-imgs-wrapper {
	margin-top: 57px;
}

.banner .banner-imgs-wrapper .col-lg-3:nth-child(2) {
	margin-top: 60px;
}

.banner .banner-imgs-wrapper .col-lg-3:nth-child(3) {
	margin-top: 60px;
}
.banner .btn-wrapper {
	margin-top: 20px;
}
/* banner social links starts here */
.banner-social-links-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
}
.banner-social-links-wrapper ul li a:hover {
	box-shadow: 97px 0px 0px var(--accent-color-2) inset;
}
.banner-social-links-wrapper ul li a {
	color: #000;
	text-decoration: none;
	padding: 5px 20px 4px 6px;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	transition: all 500ms ease-in-out;
}
.banner-social-links-wrapper ul li {
	text-align: center;
	margin: 0px 0px;
}
.banner-social-links-wrapper {
	position: absolute;
	left: -6%;
	transform: rotate(-90deg);
	top: 50%;
}
.banner-1-text-wrapper {
	background: #67e0e3;
}
/* banner social linkss ends here */
/* banner scroll link css starts here */
.banner .scroll-btn-wrapper {
	position: absolute;
	right: 0%;
	bottom: 37%;
	transform: rotate(-90deg);
}
.snap-point {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.banner .scroll-btn-wrapper a {
	background: transparent;
	font-weight: 600;
	border: 0px;
	color: #000;
	text-decoration: none;
}
.banner .video-btn-wrapper {
	position: relative;
	width: 174px;
	background: #fff;
	height: 174px;
	border-radius: 50%;
	outline: 10px solid #fff;
}
section.banner .video-btn-wrapper img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	box-shadow: 0px 0px 5px #87878794 !important;
}
section.banner .video-btn-wrapper text textPath {
	font-size: 12px;
	color: #113458;
	font-weight: 500;
}
.banner .vio-wrap {
	position: absolute;
	bottom: 8%;
	left: 3%;
}
.banner .mySwiper {
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
.banner .swiper-pagination {
	opacity: 0;
}
.banner .banner-imgs-wrapper .banner-img-wrapper img {
	height: 300px;
	object-fit: contain;
	cursor: pointer;
}
/* banner scroll link css ends here */
.ml4 {
	position: relative;
	font-weight: 900;
	font-size: 4.5em;
}
.ml4 .letters {
	position: absolute;
	margin: auto;
	left: 0;
	top: 0.3em;
	right: 0;
	opacity: 0;
}
.banner .banner-content-wrapper span.pink {
	color: var(--accent-color-2);
	font-size: 48px !important;
	margin: 0px;
	line-height: 1 !important;
	font-weight: 700;
	margin-top: 19px;
}
.banner .banner-content-wrapper h1:first-child {
	margin: 0px;
}
@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
/* banner side starts here */
.banner-side-img-wrapper ul {
	list-style: none;
	padding: 0px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.banner-side-img-wrapper ul li img {
	height: 100px;
	width: 100px;
	filter: blur(4px);
	cursor: pointer;
}
.banner-side-img-wrapper ul li img:hover {
	transition: all 0.4s ease-in-out;
	filter: blur(0px);
}
.banner-1-sec .banner-1-img-wrapper img {
	cursor: pointer;
}
.banner-side-img-wrapper ul li {
	margin: 22px 0px 0px 0px;
}
/* responsive starts here */
@media (max-width: 1440px) {
	.banner .banner-content-wrapper h1:first-child {
		font-size: 55px !important;
	}
	.banner-1-sec .banner-1-text-wrapper {
		margin-left: 135px !important;
	}
	.banner .scroll-btn-wrapper {
		position: absolute;
		right: -1%;
		bottom: 45%;
		transform: rotate(-90deg);
	}
	.banner.bannert2 {
		padding-top: 100px;
	}
	.banner .banner-imgs-wrapper {
		margin-top: 1px;
	}
	.banner .banner-imgs-wrapper .banner-img-wrapper {
		text-align: center;
	}
	.banner .banner-imgs-wrapper .banner-img-wrapper img {
		height: 274px;
		object-fit: cover;
	}
	.banner .video-btn-wrapper {
		width: 166px;
		height: 166px;
		outline: 5px solid #fff;
	}
	.banner .video-btn-wrapper svg {
		width: 166px;
		height: 166px;
	}
	.banner .btn-wrapper {
		margin-top: 5px;
	}
	.banner-1-sec .banner-1-img-wrapper img {
		height: 400px;
	}
	.banner-1-sec .banner-1-img-wrapper {
		text-align: center;
	}
	.banner .vio-wrap {
		position: absolute;
		bottom: 8%;
		left: 2%;
	}
}
@media (max-width: 1366px) {
	.banner .banner-imgs-wrapper .banner-img-wrapper img {
		height: 274px;
		object-fit: cover;
		border-radius: 27px;
	}
	.banner-1-sec .banner-1-text-wrapper {
		margin-left: 104px !important;
	}
}
@media (max-width: 1280px) {
	.banner .video-btn-wrapper text textPath {
		font-size: 12px;
	}
	.banner .video-btn-wrapper svg {
		width: 140px;
		height: 140px;
	}
	.banner .video-btn-wrapper {
		width: 140px;
		height: 140px;
		outline: 5px solid #fff;
	}
	.banner .video-btn-wrapper img {
		height: 80px;
		width: 80px;
		object-fit: cover;
	}
}
@media (max-width: 1100px) {
	.banner .banner-imgs-wrapper .banner-img-wrapper img {
		height: 201px;
	}
	.banner {
		background-position: center center;
	}
}
@media (max-width: 1024px) {
	.banner-social-links-wrapper {
		left: -10%;
	}
	.banner .video-btn-wrapper img {
		height: 66px;
		width: 66px;
	}
	.banner .video-btn-wrapper svg {
		width: 110px;
		height: 110px;
	}
	.banner .video-btn-wrapper {
		width: 110px;
		height: 110px;
	}
	section.banner .video-btn-wrapper text textPath {
		font-size: 12px;
	}
}
/* responsive ends here */
/* banner ends here */
/* marquee div container */
.after-banner .marquee {
	height: 60px;
	overflow: hidden;
	position: relative;
}
/* nested div inside the container */
.after-banner .marquee div {
	display: block;
	width: 200%;
	position: absolute;
	overflow: hidden;
	animation: marquee 20s linear infinite;
}
/* span with text */
.after-banner .marquee span {
	float: left;
	width: 50%;
}
/* keyframe */
@keyframes marquee {
	0% {
		left: 0;
	}
	100% {
		left: -100%;
	}
}
/* banner after slide ends here */
/* about starts here */
.after-banner .after-ban-wrapper {
	background: #fff !important;
	padding: 14px 0px;
	position: relative;
}
.about-sec {
	padding: 0px 0px 70px;
	/* height: 71vh; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* position: relative; */
	/* z-index: 999; */
	background: #ffff;
}
div#tagline {
	margin-bottom: 67px;
}
.about-sec .about-content-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-3);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
section#about-sec .about-img-wrapper img {
	height: 400px;
	width: 100%;
	object-fit: contain;
}
.about-sec .about-testimonials-wrapper {
	background: #f4f4f4;
	padding: 25px 17px;
	border-radius: 24px;
	margin: 17px 0px 17px 0px;
	display: flex;
	align-items: center;
}
.about-sec .about-testimonials-wrapper .about-testimonails-img-wrapper ul {
	list-style: none;
	padding: 0px;
	display: flex;
	justify-content: end;
	margin: 0px;
}
.about-sec .about-testimonials-wrapper .about-testimonails-heading-wrapper {
	flex: 0 0 50%;
}
.about-sec .about-testimonials-wrapper .about-testimonails-img-wrapper {
	flex: 0 0 50%;
}
.about-sec .about-content-wrapper h2 {
	font-size: 59px !important;
}
.about-sec .about-testimonials-wrapper .about-testimonails-heading-wrapper h5 {
	font-size: 22px;
	font-weight: 700 !important;
}
.about-sec .about-testimonials-wrapper .about-testimonails-heading-wrapper p {
	margin: 0px;
	font-style: italic;
	font-size: 16px !important;
}
.about-sec
	.about-testimonials-wrapper
	.about-testimonails-img-wrapper
	ul
	li
	img:not(:first-child) {
	position: relative;
}

.about-sec
	.about-testimonials-wrapper
	.about-testimonails-img-wrapper
	ul
	li
	img {
	height: 56px;
	width: 56px;
	object-fit: cover;
	border-radius: 50%;
	outline: 3px solid #fff;
}

.about-sec
	.about-testimonials-wrapper
	.about-testimonails-img-wrapper
	ul
	li:nth-child(even)
	img {
	position: relative;
	left: -17px;
}

.about-sec
	.about-testimonials-wrapper
	.about-testimonails-img-wrapper
	ul
	li:nth-child(odd)
	img {
	position: relative;
	right: 13px;
}
.about-sec .locations-wrapper {
	display: flex;
	justify-content: end;
}
.about-sec .locations-wrapper .location-btn img {
	height: 50px;
}
.about-sec .locations-wrapper .location-btn {
	background: transparent;
	border-radius: 37px;
	padding: 3px 21px;
	box-shadow: 2px 5px 8px #91919180;
	border: 0px;
	display: flex;
	align-items: center;
}
.about-sec .locations-wrapper .location-btn h6 {
	margin: 0px;
}
.about-sec .locations-wrapper .location-btn:first-child {
	margin-right: 139px;
}
.about-sec .locations-wrapper .location-btn:last-child {
	margin-right: 76px;
}
.about-sec .about-content-wrapper a.btn:hover {
	background: var(--accent-color-1) !important;
	color: #000 !important;
	font-weight: 600;
}
.about-sec .about-content-wrapper a.btn:hover span.btn-icon {
	background: var(--accent-color-2);
}
.about-sec .about-content-wrapper a.btn:hover span:first-child {
	text-shadow: 2px 2px 4px #545454 !important;
	font-weight: 500;
}
/* responsive starts here */
@media (max-width: 1440px) {
	.about-sec
		.about-testimonials-wrapper
		.about-testimonails-heading-wrapper
		h5 {
		font-size: 20px !important;
	}
}
@media (max-width: 1366px) {
	.about-sec .about-content-wrapper h2 {
		font-size: 49px !important;
	}
	.about-sec
		.about-testimonials-wrapper
		.about-testimonails-heading-wrapper
		h5 {
		font-size: 18px !important;
	}
	.about-sec .about-testimonials-wrapper .about-testimonails-heading-wrapper p {
		font-size: 14px !important;
	}
}
@media (max-width: 1280px) {
	.about-sec .locations-wrapper .location-btn:first-child {
		margin-right: 68px;
	}
	.koze-app .koze-app-2-wrapper .koze-app-img-wrapper img.app-scanner-img {
		left: 8%;
	}
}
@media (max-width: 1100px) {
	.about-sec .about-content-wrapper h2 {
		font-size: 41px !important;
	}
	.about-sec
		.about-testimonials-wrapper
		.about-testimonails-img-wrapper
		ul
		li
		img {
		height: 37px;
		width: 37px;
	}
	.about-sec .about-testimonials-wrapper .about-testimonails-img-wrapper {
		flex: 0 0 44%;
	}
	.about-sec .about-testimonials-wrapper .about-testimonails-heading-wrapper {
		flex: 0 0 55%;
	}
	.about-sec
		.about-testimonials-wrapper
		.about-testimonails-heading-wrapper
		h5 {
		font-size: 15px !important;
		margin-bottom: 5px;
	}
	.about-sec .about-testimonials-wrapper .about-testimonails-heading-wrapper p {
		font-size: 12px !important;
	}
	section#about-sec .about-img-wrapper img {
		height: 364px;
		width: 100%;
		object-fit: cover;
	}
	.about-sec .locations-wrapper .location-btn h6 {
		margin: 0px;
		font-size: 16px !important;
	}
}
@media (max-width: 1024px) {
	.about-sec .locations-wrapper .location-btn h6 {
		font-size: 13px !important;
	}
	.about-sec .about-content-wrapper h2 {
		font-size: 40px !important;
	}
	.about-sec
		.about-testimonials-wrapper
		.about-testimonails-img-wrapper
		ul
		li
		img {
		height: 30px;
		width: 30px;
	}
	.about-sec
		.about-testimonials-wrapper
		.about-testimonails-heading-wrapper
		h5 {
		font-size: 14px !important;
	}
	.about-sec .about-testimonials-wrapper .about-testimonails-heading-wrapper p {
		font-size: 12px !important;
	}
}
/* responsive ends here */
/* about ends here */
/* koze app starts here */
.koze-app {
	padding: 100px 0px;
	/* background: var(--accent-color-1); */
	background-image: url("../img/how-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.koze-app h6.small-heading {
	display: inline-block;
	background: var(--accent-color-3);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.koze-app .koze-app-1-wrapper {
	height: 344px;
	background: #9aebee;
	border-radius: 46px;
	padding: 26px 34px;
	display: flex;
	align-items: center;
}
.koze-app .koze-app-1-wrapper .koze-app-content-wrapper {
	flex: 0 0 61%;
}
.koze-app .koze-app-1-wrapper .koze-app-img-wrapper {
	flex: 0 0 48%;
}
.koze-app .koze-app-1-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-2);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}

.koze-app .koze-app-1-wrapper h4 {
	font-size: 26px !important;
	font-weight: 700 !important;
}
.koze-app .koze-app-1-wrapper .koze-app-content-wrapper .app-list-wrapper ul {
	padding: 0px;
	list-style: none;
}

.koze-app
	.koze-app-1-wrapper
	.koze-app-content-wrapper
	.app-list-wrapper
	ul
	li
	span {
	font-size: 19px !important;
	font-weight: 600 !important;
	color: #000;
}
.koze-app
	.koze-app-1-wrapper
	.koze-app-content-wrapper
	.app-list-wrapper
	ul
	li {
	line-height: 2.2;
}
.koze-app .koze-app-1-wrapper .buttons-wrapper a.apple-btn {
	background: #000;
	padding: 9px 22px;
	margin: 0px 10px 0px 0px;
	border-radius: 35px;
}
.koze-app .koze-app-1-wrapper .buttons-wrapper {
	display: flex;
	align-items: center;
}
.koze-app .koze-app-2-wrapper {
	height: 344px;
	background: #9aebee;
	border-radius: 46px;
	padding: 26px 34px;
	display: flex;
	align-items: center;
	width: 97%;
	margin: 0px auto;
	margin-right: 0px;
}
.koze-app .koze-app-2-wrapper .koze-app-content-wrapper {
	flex: 0 0 61%;
}
.koze-app .koze-app-2-wrapper .koze-app-img-wrapper {
	flex: 0 0 48%;
}
.koze-app .koze-app-2-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-3);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}

.koze-app .koze-app-2-wrapper h4 {
	font-size: 26px !important;
	font-weight: 700 !important;
}
.koze-app .koze-app-2-wrapper .koze-app-content-wrapper .app-list-wrapper ul {
	padding: 0px;
	list-style: none;
}

.koze-app
	.koze-app-2-wrapper
	.koze-app-content-wrapper
	.app-list-wrapper
	ul
	li
	span {
	font-size: 19px !important;
	font-weight: 600 !important;
	color: #000;
}
.koze-app
	.koze-app-2-wrapper
	.koze-app-content-wrapper
	.app-list-wrapper
	ul
	li {
	line-height: 2.2;
}

.koze-app .koze-app-1-wrapper .buttons-wrapper {
	display: flex;
}

.koze-app .koze-app-2-wrapper .koze-app-img-wrapper {
	margin-top: -59px;
	position: relative;
}

.koze-app .koze-app-2-wrapper .koze-app-img-wrapper img.app-scanner-img {
	height: 111px;
	position: absolute;
	top: 41%;
	left: 12%;
	right: 0;
}
/* responsive starts here */
@media (max-width: 1366px) {
	.koze-app .koze-app-2-wrapper h4 {
		font-size: 21px !important;
	}
	.koze-app .koze-app-2-wrapper .koze-app-img-wrapper img.app-scanner-img {
		left: 7%;
	}
	.koze-app
		.koze-app-1-wrapper
		.koze-app-content-wrapper
		.app-list-wrapper
		ul
		li
		span {
		font-size: 17px !important;
	}
	.koze-app
		.koze-app-2-wrapper
		.koze-app-content-wrapper
		.app-list-wrapper
		ul
		li
		span {
		font-size: 17px !important;
	}
	.koze-app .koze-app-1-wrapper {
		height: 327px;
	}
	.koze-app .koze-app-2-wrapper {
		height: 327px;
	}
}
@media (max-width: 1100px) {
	.koze-app .koze-app-1-wrapper h4 {
		font-size: 21px !important;
		font-weight: 700 !important;
	}
	.koze-app .shake-heading-wrapper h2 {
		font-size: 46px !important;
	}
	.koze-app .koze-app-2-wrapper .koze-app-img-wrapper img.app-scanner-img {
		left: 10%;
		width: 80px;
		object-fit: cover;
		top: 33%;
	}
}
@media (max-width: 1024px) {
	.koze-app .koze-app-2-wrapper h4 {
		font-size: 17px !important;
	}
	.koze-app
		.koze-app-2-wrapper
		.koze-app-content-wrapper
		.app-list-wrapper
		ul
		li
		span {
		font-size: 15px !important;
	}
	.koze-app .koze-app-1-wrapper h4 {
		font-size: 17px !important;
	}
	.koze-app .koze-app-2-wrapper h6.small-heading {
		display: inline-block;
		background: var(--accent-color-3);
		font-size: 13px !important;
		font-weight: bold !important;
		padding: 4px 15px;
		border-radius: 29px;
	}
}
/* responsive ends here */
/* koze app ends here */
/* print sec starts here */
.print-sec {
	padding: 60px 0px;
	/* background: #fff; */
	background-image: url("../img/anything-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	/* background-position: 0px -57px; */
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 100vh;
}
.print-sec .print-content-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-3);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.print-sec .print-content-wrapper h2 {
	margin-bottom: 5px;
}
.print-sec .print-content-wrapper p {
	font-weight: 500 !important;
}
.print-sec .print-content-wrapper h4 {
	font-size: 28px !important;
	font-weight: bold !important;
}
/* rtesponsive starts here */
@media (max-width: 1100px) {
	.print-sec {
		background-position: center right;
	}
	.play-game .play-game-content-wrapper {
		margin-right: 0px !important;
	}
}
/* print sec ends here */

/* emotions sec starts here */
.emotions-sec {
	padding: 60px 0px;
	/* background: #fff; */
	background: url("../img/emotion-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
}
.emotions-sec .emotions-content-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-3);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.emotions-sec .emotions-content-wrapper h2 span {
	line-height: 1 !important;
	display: block;
}
.emotions-sec .emotions-content-wrapper p {
	font-weight: 500 !important;
}
.emotions-sec .emotions-content-wrapper h4 {
	font-size: 28px !important;
	font-weight: bold !important;
}
/* emotions sec ends here */
/* play games starts here */
.play-game {
	/* background: var(--accent-color-2); */
	background-image: url("../img/game-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 0px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.play-game .play-game-content-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-3);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.play-game .play-game-content-wrapper p {
	font-weight: 500 !important;
}
.play-game .play-game-content-wrapper .buttons-wrapper a.apple-btn {
	background: #000;
	padding: 16px 30px;
	margin: 0px 10px 0px 0px;
	border-radius: 35px;
}
.play-game .play-game-content-wrapper .buttons-wrapper {
	margin-top: 51px;
}
.play-game .play-img-wrapper img {
	animation: float 6s ease-in-out infinite;
}
.play-img-wrapper2 {
	opacity: 0;
}
.play-game-content-wrapper2 {
	opacity: 0;
}
.mySwipers {
	height: 100vh;
}
iframe#webpack-dev-server-client-overlay {
	display: none;
}
.banner-trans {
	transition: all 0.6s ease-in-out;
}
.blur-sec-wrapper {
	position: relative;
	z-index: 999;
}
.pan:not(.pan) {
	opacity: 0;
	visibility: hidden;
	transform: scale(0.8);
}
@-webkit-keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translatey(0px);
	}
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
/* play games ends here */
/* earn points starts here */
.earn-game {
	/* background: #f9ec6c; */
	background-image: url("../img/coin-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 0px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.earn-game .earn-game-content-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-2);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.earn-game .earn-game-content-wrapper p {
	font-weight: 500 !important;
}
.earn-game .earn-game-content-wrapper h2 {
	font-size: 60px !important;
}
@media (max-width: 1280px) {
	.earn-game .earn-game-content-wrapper h2 {
		font-size: 50px !important;
	}
}
/* earn points ends here */
/* shakes starts here */
.shake-sec {
	padding: 50px 0px;
	/* background: #fff; */
	background-image: url("../img/soft-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
}
.shake-sec .shake-biscuits-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
}
.shake-sec .shake-biscuits-wrapper ul img {
	height: 190px;
	width: 190px;
	object-fit: cover;
}
.shake-sec .shake-biscuits-wrapper ul li {
	transition: 0.6s;
}

.shake-sec .shake-biscuits-wrapper ul li:hover {
	transform: scale(0.8) rotate(45deg);
}
.shake-sec .shake-biscuits-wrapper ul li {
	flex: 0 0 33%;
	margin: 18px 0px;
}
.shake-sec .shake-heading-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-3);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.shake-sec .shake-heading-wrapper h2 span::after {
	content: url("../img/shake-emoji.webp");
	position: absolute;
}

.shake-sec .button-wrapper a.btn {
	background: var(--accent-color-2);
	border-radius: 29px;
	padding: 4px 0px 4px 41px;
	display: inline-flex;
	align-items: center;
	color: #fff;
	box-shadow: 1px 4px 5px #ffffff9e inset, 0px 3px 3px #87878794 !important;
	border: 0px;
}
.shake-sec .button-wrapper a.btn span:first-child {
	text-shadow: 2px 2px 4px #545454;
	font-weight: 500;
}
.shake-sec .button-wrapper a.btn span.btn-icon {
	background: var(--accent-color-1);
	width: 42px;
	display: inline-block;
	height: 42px;
	margin-left: 33px;
	margin-right: 4px;
	line-height: 2.2;
	font-size: 19px;
	border-radius: 50%;
	box-shadow: 1px 4px 5px #ffffff9e inset;
}
.shake-sec .button-wrapper a.btn span.btn-icon i {
	color: #000;
}
.shake-sec a.btn:hover {
	background: var(--accent-color-1) !important;
	color: #000 !important;
	font-weight: 600;
}
.shake-sec a.btn:hover span.btn-icon {
	background: var(--accent-color-2);
}
.shake-sec a.btn:hover span:first-child {
	text-shadow: 2px 2px 4px #545454 !important;
	font-weight: 500;
}
/* responsive starts here */
@media (max-width: 1440px) {
	.shake-sec .shake-biscuits-wrapper ul img {
		height: 158px;
		width: 158px;
		object-fit: cover;
	}
	.shake-sec .shake-biscuits-wrapper img {
		height: 324px;
		object-fit: cover;
	}
	.shake-sec {
		padding: 25px 0px;
	}
}
@media (max-width: 1280px) {
	.shake-sec .shake-biscuits-wrapper ul img {
		height: 145px;
		width: 145px;
		object-fit: cover;
	}
}
@media (max-width: 1100px) {
	.shake-sec .shake-biscuits-wrapper img {
		height: 324px;
		object-fit: contain;
	}
}
/* responsive ends here */
/* shakes ends here */
/* koze cookies starts here */
.combo-shake {
	/* background: var(--accent-color-2); */
	background-image: url("../img/ko-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 0px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.combo-shake .combo-shake-content-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-3);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.combo-shake .combo-shake-content-wrapper p {
	font-weight: 500 !important;
}
.combo-shake .combo-shake-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
}
.combo-shake .combo-shake-wrapper ul li .combo-shake-card-wrapper {
	width: 165px;
	margin: 0px 0px 18px 0px;
}
.combo-shake
	.combo-shake-wrapper
	ul
	li
	.combo-shake-card-wrapper
	.combo-shake-koze-heading
	h5 {
	font-style: italic;
	font-size: 18px !important;
	font-weight: 600 !important;
}
.combo-shake
	.combo-shake-wrapper
	ul
	li
	.combo-shake-card-wrapper
	.combo-shake-img-wrapper
	img {
	height: 93px;
}
.combo-shake .combo-shake-wrapper ul li {
	flex: 0 0 33.33%;
}
.combo-shake .combo-shake-content-wrapper .combo-shake-wrapper {
	width: 80%;
	margin-top: 39px;
}
.combo-shake
	.combo-shake-wrapper
	ul
	li
	.combo-shake-card-wrapper
	.combo-shake-koze-heading {
	margin-top: 20px;
}
/* responsive starts here */
@media (max-width: 1366px) {
	.combo-shake .combo-shake-wrapper ul li .combo-shake-card-wrapper {
		width: 142px;
	}
}
@media (max-width: 1280px) {
	.combo-shake
		.combo-shake-wrapper
		ul
		li
		.combo-shake-card-wrapper
		.combo-shake-img-wrapper
		img {
		height: 76px;
	}
	.combo-shake
		.combo-shake-wrapper
		ul
		li
		.combo-shake-card-wrapper
		.combo-shake-koze-heading {
		margin-top: 13px;
	}
}
/* responsive ends here */
/* kozze cookies ends here */
/* koze cookies starts here */
.koze-cookies {
	/* background: #f9ec6c; */
	background-image: url("../img/ck-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	padding: 50px 0px;
	height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.koze-cookies .koze-cookies-content-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-1);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.koze-cookies .koze-cookies-content-wrapper p {
	font-weight: 500 !important;
}
.koze-cookies .cookies-shake-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
}
.koze-cookies .cookies-shake-wrapper ul li .cookies-card-wrapper {
	width: 159px;
	margin: 0px 0px 18px 0px;
}
.koze-cookies
	.cookies-shake-wrapper
	ul
	li
	.cookies-card-wrapper
	.cookies-koze-heading
	h5 {
	font-style: italic;
	font-size: 18px !important;
	font-weight: 600 !important;
}
.koze-cookies
	.cookies-shake-wrapper
	ul
	li
	.cookies-card-wrapper
	.cookies-img-wrapper
	img {
	height: 93px;
}
.koze-cookies .cookies-shake-wrapper ul li {
	flex: 0 0 33.33%;
}
.koze-cookies .koze-cookies-content-wrapper .cookies-shake-wrapper {
	width: 80%;
	margin-top: 39px;
}
.koze-cookies
	.cookies-shake-wrapper
	ul
	li
	.cookies-card-wrapper
	.cookies-koze-heading {
	margin-top: 20px;
}
/* responsive starts here */
@media (max-width: 1366px) {
	.koze-cookies .cookies-shake-wrapper ul li .cookies-card-wrapper {
		width: 142px;
	}
}
@media (max-width: 1280px) {
	.koze-cookies
		.cookies-shake-wrapper
		ul
		li
		.cookies-card-wrapper
		.cookies-koze-heading {
		margin-top: 8px;
	}
}
@media (max-width: 1100px) {
	.koze-cookies .koze-cookies-content-wrapper .cookies-shake-wrapper {
		width: 100%;
		margin-top: 39px;
	}
}
/* responsive ends here */
/* kozze cookies ends here */
/* Newsletter starts here */
.newsletter {
	padding-top: 51px;
	/* background: #fff;
	z-index: 999;
	position: relative; */
}
.home-footer-wrapper .newsletter {
	background: #fff;
	position: relative;
}
.newsletter .newsletter-text-wrapper h2 {
	font-size: 58px !important;
}
.newsletter .newsletter-text-wrapper h2 span {
	display: block;
}
.newsletter .newsletter-card-wrapper .newsletter-heading-wrapper h2 {
	font-size: 43px !important;
}
.newsletter .newsletter-card-wrapper .newsletter-fields-wrapper {
	display: flex;
	padding: 5px 11px;
	border-radius: 41px;
	border: 1px solid #000000;
	align-items: center;
	box-shadow: -4px 12px 22px -10px rgba(0, 0, 0, 0.31);
}
.newsletter
	.newsletter-card-wrapper
	.newsletter-fields-wrapper
	.news-input-wrapper {
	flex: 0 0 72%;
}

.newsletter .newsletter-card-wrapper .newsletter-fields-wrapper button.btn {
	flex: 0 0 29%;
	height: 60px;
}
.newsletter
	.newsletter-card-wrapper
	.newsletter-fields-wrapper
	.news-input-wrapper
	input {
	border: 0px;
}

.newsletter
	.newsletter-card-wrapper
	.newsletter-fields-wrapper
	.news-input-wrapper
	input:focus-visible {
	outline: none;
	box-shadow: none;
	border: 0px;
}

.newsletter .newsletter-card-wrapper .newsletter-fields-wrapper .btn {
	background: var(--accent-color-2);
	border-radius: 29px;
	padding: 4px 0px 4px 41px;
	display: inline-flex;
	align-items: center;
	color: #fff;
	box-shadow: 1px 4px 5px #ffffff9e inset;
	border: 0px;
}
.newsletter
	.newsletter-card-wrapper
	.newsletter-fields-wrapper
	.btn
	span:first-child {
	text-shadow: 2px 2px 4px #545454;
	font-weight: 500;
}
.newsletter
	.newsletter-card-wrapper
	.newsletter-fields-wrapper
	.btn
	span.btn-icon {
	background: var(--accent-color-1);
	width: 42px;
	display: inline-block;
	height: 42px;
	margin-left: 33px;
	margin-right: 4px;
	line-height: 2.2;
	font-size: 19px;
	border-radius: 50%;
	box-shadow: 1px 4px 5px #ffffff9e inset;
}
.newsletter
	.newsletter-card-wrapper
	.newsletter-fields-wrapper
	.btn
	span.btn-icon
	i {
	color: #000;
}
.newsletter button.btn:hover {
	background: var(--accent-color-1) !important;
	color: #000 !important;
	font-weight: 600;
}
.newsletter button.btn:hover span.btn-icon {
	background: var(--accent-color-2) !important;
}
.newsletter button.btn:hover span:first-child {
	text-shadow: 2px 2px 4px #545454 !important;
	font-weight: 500;
}
/* responsive starts here */
@media (max-width: 1366px) {
	.newsletter
		.newsletter-card-wrapper
		.newsletter-fields-wrapper
		.news-input-wrapper {
		flex: 0 0 67%;
	}
	.newsletter .newsletter-text-wrapper h2 {
		font-size: 49px !important;
		line-height: 1 !important;
	}
	.newsletter .newsletter-card-wrapper .newsletter-heading-wrapper h2 {
		font-size: 37px !important;
		line-height: 1 !important;
	}
}
@media (max-width: 1100px) {
	.newsletter .newsletter-text-wrapper h2 {
		font-size: 42px !important;
		line-height: 1 !important;
	}
	.newsletter
		.newsletter-card-wrapper
		.newsletter-fields-wrapper
		.news-input-wrapper {
		flex: 0 0 60%;
	}
	.newsletter .newsletter-card-wrapper .newsletter-fields-wrapper button.btn {
		flex: 0 0 36%;
	}
}
@media (max-width: 800px) {
	.newsletter .newsletter-card-wrapper .newsletter-fields-wrapper button.btn {
		flex: 0 0 28%;
	}
	.newsletter
		.newsletter-card-wrapper
		.newsletter-fields-wrapper
		.news-input-wrapper {
		flex: 0 0 72%;
	}
	.newsletter {
		padding-bottom: 40px;
	}
}
@media (max-width: 480px) {
	.newsletter .newsletter-card-wrapper .newsletter-fields-wrapper button.btn {
		flex: 0 0 40%;
	}
	.newsletter
		.newsletter-card-wrapper
		.newsletter-fields-wrapper
		.news-input-wrapper {
		flex: 0 0 60%;
	}
	.newsletter .newsletter-text-wrapper h2 {
		font-size: 31px !important;
		line-height: 1.5 !important;
	}
	.newsletter .newsletter-card-wrapper .newsletter-heading-wrapper h2 {
		font-size: 31px !important;
		line-height: 1.5 !important;
	}
}
@media (max-width: 425px) {
	.newsletter
		.newsletter-card-wrapper
		.newsletter-fields-wrapper
		.news-input-wrapper {
		flex: 0 0 54%;
	}
}
@media (max-width: 414px) {
	.newsletter .newsletter-card-wrapper .newsletter-fields-wrapper {
		display: flex;
		padding: 0px;
		border-radius: 0px;
		border: 0px;
		align-items: center;
		box-shadow: none;
		flex-direction: column;
	}
	.newsletter .newsletter-card-wrapper .newsletter-fields-wrapper button.btn {
		flex: 0 0 100%;
		width: 100%;
	}
	.newsletter
		.newsletter-card-wrapper
		.newsletter-fields-wrapper
		.btn
		span:first-child {
		flex: 0 0 75%;
	}
	.newsletter
		.newsletter-card-wrapper
		.newsletter-fields-wrapper
		.news-input-wrapper {
		flex: 0 0 100%;
		width: 100%;
		padding: 7px 0px;
		border-radius: 35px;
		border: 1px solid #000000;
		align-items: center;
		box-shadow: -4px 12px 22px -10px rgba(0, 0, 0, 0.31);
		overflow: hidden;
		margin-bottom: 10px;
	}
}
/* responsive ends here */
/* Newsletter ends here */
/* Footer starts here */
/* footer {
	background: #fff;
	position: relative;
	z-index: 99;
} */
.footer-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #fff;
}
.home-footer-wrapper footer {
	position: relative;
	background: #fff;
}
footer .footer-links-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 80%;
	margin: 0px auto;
}
footer .footer-links-wrapper .footer-menu-card-wrapper ul {
	padding: 0px;
	list-style: none;
}
footer .footer-links-wrapper .footer-menu-card-wrapper ul li a {
	text-decoration: none;
	line-height: 2;
	color: #000;
	font-family: "Poppins";
	font-weight: 400;
	font-size: 14px;
}
footer .footer-links-wrapper .footer-menu-card-wrapper .footer-name-wrapper h5 {
	font-size: 20px !important;
	font-weight: 700 !important;
}
footer .footer-links-wrapper .footer-menu-card-wrapper ul li a:hover {
	color: var(--accent-color-2);
}
footer .footer-last-card .footer-heading h4 {
	font-size: 26px !important;
}
footer .footer-links-wrapper .footer-menu-card-wrapper .footer-link-wrap {
	margin-top: 14px;
}
footer .footer-apple-wrapper .buttons-wrapper a.apple-btn {
	background: #000;
	padding: 9px 13px;
	margin: 0px 5px 0px 0px;
	border-radius: 35px;
	height: 45px;
	display: inline-block;
	text-align: center;
}
footer .footer-apple-wrapper .buttons-wrapper a.apple-btn img {
	width: 85%;
	margin: 0 auto;
}
footer .footer-last-card .footer-heading p {
	font-size: 14px !important;
}
footer .copyright-text-wrapper {
	text-align: center;
}
footer .footer-links-wrapper .footer-menu-card-wrapper ul li a.active {
	color: var(--accent-color-2);
}
footer .copyright-text-wrapper p {
	font-size: 13px !important;
	font-weight: 400 !important;
	opacity: 60%;
}
footer .footer-logo-wrapper {
	position: relative;
	left: -53%;
}
/* responsive starts here */
@media (max-width: 1366px) {
	footer .footer-logo-wrapper {
		left: -60%;
		position: relative;
	}
	footer .footer-logo-wrapper img {
		transform: scale(0.9);
	}
	footer .footer-last-card .footer-heading p {
		font-size: 12px !important;
	}
	footer .footer-last-card .footer-heading h4 {
		font-size: 23px !important;
	}
	footer .footer-apple-wrapper .buttons-wrapper {
		display: flex;
	}
}
@media (max-width: 1100px) {
	footer .footer-logo-wrapper {
		left: -87%;
	}
	footer .footer-last-card .footer-heading h4 {
		font-size: 19px !important;
	}
}
@media (max-width: 800px) {
	footer .footer-logo-wrapper {
		left: unset;
	}
	footer .footer-links-wrapper {
		width: 100%;
	}
	footer .footer-last-card .footer-heading h4 {
		font-size: 21px !important;
	}
	.footer-last-card {
		margin-top: 40px;
		margin-bottom: 40px;
	}
	.newsletter .newsletter-text-wrapper {
		margin-bottom: 53px;
	}
}
@media (max-width: 480px) {
	footer .footer-logo-wrapper {
		left: -62px;
	}
}
@media (max-width: 414px) {
	footer .footer-links-wrapper {
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
	}
}
/* responsive ends here */
/* Footer ends here */
/* .img-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	max-width: 500px;
	--height: 0%;
}
.img-container img {
	width: 100%;
	opacity: 0;
}
.img-container:after {
	content: "";
	position: absolute;
	width: 100%;
	height: var(--height);
	bottom: 0;
	left: 0;
	background: #000000;
} */
